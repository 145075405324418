import React from 'react';
import { useEffect, useState } from 'react';
import './App.css';

const nfts = [
  {   
    "description": "Bob is BADMAN #1.",      
    "image": "https://endlessprojects.io/badmanmfgco/1.jpg",  
    "name": "Bob #1",   
    "attributes": [
        { "trait_type": "Hair", "value": "Bob Locks" },      
        { "trait_type": "Shirt", "value": "Track Jacket" },      
        { "trait_type": "Shirt Color","value": "Green" },
    ]
 },
 {   
   "description": "Bob is BADMAN #2.",      
   "image": "https://endlessprojects.io/badmanmfgco/1.png",  
   "name": "Bob #2",   
   "attributes": [
       { "trait_type": "Hair", "value": "Bob Locks" },      
       { "trait_type": "Shirt", "value": "Track Jacket" },      
       { "trait_type": "Shirt Color","value": "Green" },
   ]
}
];

function App() {
  return (
    <main>
      <h1>Badman Skulls</h1>
      <h2>
        999 NFTs minting at{' '}
        <a
          href="https://mint1.badmanskulls.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          Badman Skulls
        </a>
      </h2>
      <ul class="list">
      {nfts.map((nft) => (
        <li class="listing" key={nft.name}>
        <p>{nft.name}</p>
        <img 
          class="thumb"
          src={nft.image}
          alt={nft.name}
          title={nft.name}
        />
        </li>
      ))}
      </ul>
    </main>
  );
}

export default App;
